
import { mapActions } from 'vuex';
import { AUTH_LOGIN } from '@/components/auth/helpers/auth-component-names';

export default {
  name: 'LayoutDefault',
  head() {
    return {
      bodyAttrs: {
        class: this.$exp.$classes.join(' '),
      },
      htmlAttrs: {
        lang: this.$i18n.locale.split('-')[0] || 'en',
      },
      style: [
        // Add a <style> tag with your custom CSS variables and styles
        {
          cssText: this.styles,
        },
      ],
    };
  },
  computed: {
    pilatesStyles() {
      return {
        ':root': {
          '--c-primary': '#007BBD !important',
          '--c-primary-dark': '#007BBD !important',
          '--c-primary-light': '#3B99CC !important',
        },
      };
    },
    menStyles() {
      return {
        ':root': {
          '--c-primary': '#2A429B !important',
          '--c-primary-dark': '#2A429B !important',
        },
      };
    },
    ketoStyles() {
      return {
        ':root': {
          '--c-primary': '#3B7841 !important',
          '--c-primary-dark': '#3B7841 !important',
          '--c-primary-light': '#3B99CC !important',
          '--c-primary-light-1': '#4D8A53 !important',
        },
        '.main-header': {
          '--c-background-color': '#F5F5EE !important',
        },
      };
    },
    medsStyles() {
      return {
        ':root': {
          '--c-primary': '#00A2CE !important',
          '--c-primary-dark': '#00A2CE !important',
          '--c-primary-light': '#C7E2E9 !important',
        },
        '.main-header': {
          '--c-background-color': '#FFFCF4 !important',
        },
        '.button': {
          'box-shadow': 'none !important',
        },
      };
    },
    yogaStyles() {
      return {
        ':root': {
          '--c-primary': 'var(--yoga-primary) !important',
          '--c-primary-dark': 'var(--yoga-primary-dark) !important',
          '--c-primary-light': 'var(--yoga-primary-light) !important',
        },
        '.main-header': {
          '--c-background-color': 'var(--yoga-primary) !important',
        },
        '.button': {
          'box-shadow': 'none !important',
        },
      };
    },
    somaticYogaStyles() {
      return {
        ':root': {
          '--c-primary': 'var(--somatic-yoga-primary) !important',
          '--c-primary-dark': 'var(--somatic-yoga-primary-dark) !important',
          '--c-primary-light': 'var(--somatic-yoga-primary-light) !important',
        },
        '.main-header': {
          '--c-background-color': 'var(--somatic-yoga-primary) !important',
        },
        '.button': {
          'box-shadow': 'none !important',
        },
      };
    },
    quizVariantStyles() {
      return {
        [this.QUIZ_VARIANTS.MEN]: this.menStyles,
        [this.QUIZ_VARIANTS.PILATES_NEW_CHECKOUT]: this.pilatesStyles,
        [this.QUIZ_VARIANTS.PILATES_NOW]: this.pilatesStyles,
        [this.QUIZ_VARIANTS.PILATES_NEW_LANDER]: this.pilatesStyles,
        [this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_LANDER]: this.pilatesStyles,
        [this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_CHECKOUT]: this.pilatesStyles,
        [this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_REMOVE_ATHLETIC_BODY_OPTION]: this.pilatesStyles,
        [this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_UPDATE_WOMEN_JOINED_NUMBER]: this.pilatesStyles,
        [this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_SOMATIC_PILATES]: this.pilatesStyles,
        [this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_ADD_ANALYSIS_STEP_V1]: this.pilatesStyles,
        [this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_ADD_ANALYSIS_STEP_V2]: this.pilatesStyles,
        [this.QUIZ_VARIANTS.PILATES_LEGAL_DISCLAIMER]: this.pilatesStyles,
        [this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_SECONDARY_TEXT]: this.pilatesStyles,
        [this.QUIZ_VARIANTS.NUTRITION_KETO]: this.ketoStyles,
        [this.QUIZ_VARIANTS.NUTRITION_KETO_MEAL]: this.ketoStyles,
        [this.QUIZ_VARIANTS.NUTRITION_KETO_WEEKLY]: this.ketoStyles,
        [this.QUIZ_VARIANTS.NUTRITION_KETO_WEEKLY_AGE]: this.ketoStyles,
        [this.QUIZ_VARIANTS.NUTRITION_KETO_MEAL_WEEKLY]: this.ketoStyles,
        [this.QUIZ_VARIANTS.NUTRITION_KETO_AGE]: this.ketoStyles,
        [this.QUIZ_VARIANTS.MEDS]: this.medsStyles,
        [this.QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE]: this.yogaStyles,
        [this.QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE_REMOVE_ATHLETIC_TYPE]: this.yogaStyles,
        [this.QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE_WEIGHT_GOAL_STEP]: this.yogaStyles,
        [this.QUIZ_VARIANTS.YOGA_MENOPAUSE_CHALLENGE]: this.yogaStyles,
        [this.QUIZ_VARIANTS.SOMATIC_YOGA]: this.somaticYogaStyles,
      };
    },
    styles() {
      return Object.entries(this.quizVariantStyles[this.getQuizVariant] || {})
        .map(([key, value]) =>
          typeof value === 'object'
            ? `${key} { ${Object.entries(value)
                .map(([prop, val]) => `${prop}: ${val};`)
                .join(' ')} }`
            : `${key}: ${value}`,
        )
        .join(' ');
    },
  },
  watch: {
    $route() {
      this.setAuthComponentName(AUTH_LOGIN);
    },
  },
  mounted() {
    // This method will validate all query in the url and store them in vuex
    this.queryHandler();

    if (window && window.balanceText && typeof window.balanceText === 'function') {
      window.balanceText('.balance-text');
    }
  },
  methods: {
    ...mapActions('auth', ['setAuthComponentName']),
  },
};
